/** @jsx jsx */
import React, { Component } from "react"
import { jsx } from "theme-ui"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as starRegular } from '@fortawesome/free-regular-svg-icons'
// import { faApple, faAndroid, iconAndroid } from '@fortawesome/free-brands-svg-icons'

type ProjectCardProps = {
  googlelink: string,
  applelink: string,
  image: any,
  stars: string,
  title: string,
  desc: string,
  genre: string,
  children: React.ReactNode,
}

const ProjectCard2 = ({ googlelink, applelink, image, stars, title, desc, genre, children }: ProjectCardProps, starblock) => (

  <div className="row game-card mobile" sx={{
    display: `flex`,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    background: 'rgba(0,0,0,0.5)',
    flexWrap: 'wrap',
    marginTop: '1rem'
  }}>

    <div className="col-lg-12 col-xl-5 game-card-left" sx={{
      flex: [`0 0 100%`,`0 0 100%`, `0 0 37%`],
    }}>
      {/* <a href={image} data-lightbox="screenshots_dark"> */}
      {/* <div className="overlay">
          <i className="fa fa-picture-o fa-3x"></i>
        </div> */}
      {/* <picture> */}
      {/* <source media="(min-width: 1200px)" srcSet={image}></source> */}
      {/* <source media="(min-width: 768px)" srcSet={image}></source> */}
      <picture sx={{
        width: '100%',
        height: '100%'
        // maxHeight: '45vh'
      }}>
        <source srcset={image+'.webp'} type="image/webp"></source> 
        <source srcset={image+'.jpg'} type="image/jpeg"></source> 
        <img src={image+'.jpg'} className="img-fluid b-lazy b-loaded" alt={title} sx={{
        width: '100%',
        height: '100%'
        // maxHeight: '45vh'
      }}></img>
      </picture>
      {/* <img src={image} className="img-fluid b-lazy b-loaded" alt={title} sx={{
        width: '100%',
        height: '100%'
        // maxHeight: '45vh'
      }}></img> */}
      {/* </picture> */}
      {/* </a> */}
      {/* <a href="images/game2.1.jpg" data-lightbox="screenshots_dark"></a> */}
      {/* <a href="images/game2.2.jpg" data-lightbox="screenshots_dark"></a> */}
    </div>
    <div className="col-lg-12 col-xl-7 game-card-right" sx={{
      flex: [`0 0 100%`,`0 0 100%`, `0 0 63%`],
      display: `flex`,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1.5rem'
    }}>
      <div className="shortinfo" sx={{
        width: '100%'
      }}>
      <h2 className="short-hr-left" sx={{
        width: '100%',
        margin: 0,
        lineHeight: ['1.4rem','1.4rem','1rem'],
        marginBottom: '1rem',
        "::after": {
          borderTop: '2px solid',
          borderColor: '#E48632',
          content: '""',
          display: 'block',
          height: '1px',
          width: '60px',
          margin: '13px 0 0 0'
        }
      }}>{title}</h2>
      <div className="tags" sx={{
        width: '100%',
        // color: '#dadada',
        color: `textMuted`,
        fontSize: '0.9rem',
      }}><span className="subtle">{genre}</span></div>
      </div>
      <div className="game-description">{children}</div>
      <div className="bottom" sx={{
        display: `flex`,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        marginTop: '1rem',
        minHeight: '10vh',
        flexWrap: 'wrap',
      }}>
        <div className="buttons" sx={{
          display: `flex`,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          minHeight: '8vh',
          // marginTop: '1rem',
          flex: '1 1 66%'
        }}>
          {/* <a href="#" className="buttonStore" sx={{
          background: '#ddd',
          borderRadius: '1rem',
          padding: '0.5rem 1rem',
        }}>
          <FontAwesomeIcon icon={iconAndroid} size="2x" />
          <p>Available on<br></br><span className="strong">Google Play</span></p>
        </a> */}
        { googlelink && applelink && <a href={googlelink} target='_blank' sx={{
            flex: `1 1 50%`,
            background: "url('https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png') center center no-repeat",
            backgroundSize: '106%',
          }}></a>}

          { googlelink && applelink && <a href={applelink} target='_blank' sx={{
            flex: `1 1 50%`,
            background: "url('https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-09-29&kind=iossoftware&bubble=ios_apps') center center no-repeat",
            backgroundSize: '92%'
          }}></a> }


        { googlelink && !applelink && <a href={googlelink} target='_blank' sx={{
            flex: `1 1 50%`,
            background: "url('https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png') center center no-repeat",
            backgroundSize: '106%',
          }}></a> }

            {/* <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' sx={{width: `100%`}}/> */}

            { applelink && !googlelink && <a href={applelink} target='_blank' sx={{
            flex: `1 1 50%`,
            background: "url('https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-09-29&kind=iossoftware&bubble=ios_apps') center center no-repeat",
            backgroundSize: '50%'
          }}></a>}
          {/* <a href="#" className="buttonStore" sx={{
          background: '#ddd',
          borderRadius: '1rem',
          padding: '0.5rem 1rem',
        }}>
          <FontAwesomeIcon icon={faApple} size="2x" />
          <p>Available on<br></br><span className="strong">Apple Store</span></p>
        </a> */}
        </div>
        <div className="rating" sx={{
          flex: '1 1 33%',
          display: `flex`,
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
        }}>
          <div className="strong" sx={{
            fontSize: '1.4rem',
            fontWeight: 'bold'
          }}>{stars}</div>
          <ul sx={{
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            display: `flex`,
            flexDirection: 'row',
            justifyContent: 'center',
            color: '#E48632'
          }}>
            {starblock}
          </ul>
        </div>
      </div>
    </div>
  </div>
)



class ProjectCard extends Component<ProjectCardProps> {
  constructor(props: ProjectCardProps) {
    super(props)

    let { googlelink, applelink, image, stars, title, desc, genre, children } = props
    let starsNumber = parseFloat(stars)
    let fullStars = Math.trunc(starsNumber)
    this.starBlock = []
    for(let i=0; i < fullStars; i++){
      this.starBlock.push(<li><FontAwesomeIcon icon={faStar} /></li>)
    }
    if(this.starBlock.length<5 && starsNumber%1 >= 0.5) {
      this.starBlock.push(<li><FontAwesomeIcon icon={faStarHalfAlt} /></li>)
    }
    while(this.starBlock.length<5){
      this.starBlock.push(<li><FontAwesomeIcon icon={starRegular} /></li>)
    }
    // this.setState({
    //   starBlock
    // });
  }

  // The tick function sets the current state. TypeScript will let us know
  // which ones we are allowed to set.
  tick() {
    // this.setState({

    // });
  }

  // Before the component mounts, we initialise our state
  componentWillMount() {
  }

  // After the component did mount, we set the state each second.
  componentDidMount() {
  }

  // render will know everything!
  render() {

    return ProjectCard2(this.props, this.starBlock)
  }
}

export default ProjectCard
