import React from "react"
import ProjectCard from "../@lekoarts/gatsby-theme-cara/components/project-card"

export default {
  // eslint-disable-next-line react/display-name
  ProjectCard: ({ googlelink, applelink, title, image, stars, children, desc, genre }) => (
    <ProjectCard  googlelink={googlelink} applelink={applelink} image={image} title={title} desc={desc} genre={genre} stars={stars}>
      {children}
    </ProjectCard>
  ),
}
